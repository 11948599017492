<template>
    <div class="app-container">
        <el-row :gutter="20">
            <!--部门数据-->
            <el-col :span="4" :xs="24">
                <div class="head-container">
                    <el-input v-model="deptName" placeholder="请输入部门名称" clearable size="small" prefix-icon="el-icon-search" style="margin-bottom: 20px" />
                </div>
                <div class="head-container">
                    <el-tree :data="deptOptions" :props="defaultProps" :expand-on-click-node="false" :filter-node-method="filterNode" ref="tree" node-key="deptId" default-expand-all highlight-current @node-click="handleNodeClick" />
                </div>
            </el-col>
            <!--用户数据-->
            <el-col :span="20" :xs="24">
                <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="90px">
                    <el-form-item label="用户名称：" prop="userName">
                        <el-input v-model="queryParams.userName" placeholder="请输入用户名称" clearable class="width_170" @keyup.enter.native="handleQuery" />
                    </el-form-item>
                    <el-form-item label="手机号码：" prop="phoneNumber">
                        <el-input v-model="queryParams.phoneNumber" placeholder="请输入手机号码" clearable class="width_170" @keyup.enter.native="handleQuery" />
                    </el-form-item>
                    <el-form-item label="状态：" prop="status">
                        <el-select v-model="queryParams.status" placeholder="用户状态" clearable class="width_170">
                            <el-option label="正常" value="0" />
                            <el-option label="停用" value="1" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="创建时间：" class="dateBox" prop="startTime">
                        <el-row style="width: 320px">
                            <el-col :span="11">
                                <el-date-picker placeholder="开始时间" v-model="queryParams.startCreateTime" type="date" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%"> </el-date-picker>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="11">
                                <el-date-picker placeholder="结束时间" v-model="queryParams.endCreateTime" type="date" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%"> </el-date-picker>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="mb8">
                    <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
                    <!-- <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete">删除</el-button> -->
                </div>
                <el-table v-loading="loading" :data="userList" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="50" align="center" />
                    <el-table-column label="用户编号" align="center" key="userId" prop="userId" v-if="columns[0].visible" />
                    <el-table-column label="用户名称" align="center" key="userName" prop="userName" v-if="columns[1].visible" :show-overflow-tooltip="true" />
                    <el-table-column label="用户昵称" align="center" key="nickName" prop="nickName" v-if="columns[2].visible" :show-overflow-tooltip="true" />
                    <el-table-column label="组织" align="center" key="deptName" prop="deptName" v-if="columns[3].visible" :show-overflow-tooltip="true" />
                    <el-table-column label="手机号码" align="center" key="phoneNumber" prop="phoneNumber" v-if="columns[4].visible" width="120" />
                    <el-table-column label="状态" align="center" key="status" v-if="columns[5].visible">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" active-value="0" inactive-value="1" @change="handleStatusChange(scope.row)"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" align="center" prop="createTime" v-if="columns[6].visible" width="150">
                        <template slot-scope="scope">
                            <span>{{ parseTime(scope.row.createTime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                        <template slot-scope="scope" v-if="scope.row.userId !== -1">
                            <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">修改</el-button>
                            <!-- <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row)"
                >删除</el-button> -->
                            <!-- <el-dropdown size="mini" @command="(command) => handleCommand(command, scope.row)">
                  <el-button size="mini" type="text" icon="el-icon-d-arrow-right">更多</el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="handleResetPwd" icon="el-icon-key"
                      >重置密码</el-dropdown-item>
                    <el-dropdown-item command="handleAuthRole" icon="el-icon-circle-check"
                      >分配角色</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown> -->
                        </template>
                    </el-table-column>
                </el-table>

                <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getList" />
            </el-col>
        </el-row>

        <!-- 添加或修改用户配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="用户昵称" prop="nickName">
                            <el-input v-model="form.nickName" placeholder="请输入用户昵称" maxlength="30" />
                        </el-form-item>
                    </el-col>
                    <!--                    <el-col :span="12">-->
                    <!--                        <el-form-item label="租户" prop="tenantId">-->
                    <!--                            <treeselect v-model="form.tenantId" :options="tenantOptions" :show-count="true" placeholder="请选择归属租户" />-->
                    <!--                        </el-form-item>-->
                    <!--                    </el-col>-->
                    <el-col :span="12">
                        <el-form-item label="归属组织" prop="deptId">
                            <treeselect v-model="form.deptId" :options="deptOptions" :show-count="true" placeholder="请选择归属组织" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="手机号码" prop="phoneNumber">
                            <el-input v-model="form.phoneNumber" placeholder="请输入手机号码" maxlength="11" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="form.email" placeholder="请输入邮箱" maxlength="50" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item v-if="!form.userId" label="用户名称" prop="userName">
                            <el-input v-model="form.userName" placeholder="请输入用户名称" maxlength="30" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="!form.userId" label="用户密码" prop="password">
                            <el-input v-model="form.password" placeholder="请输入用户密码" type="password" maxlength="20" show-password />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="用户性别">
                            <el-select v-model="form.sex" placeholder="请选择性别" clearable>
                                <el-option label="男" value="0" />
                                <el-option label="女" value="1" />
                                <el-option label="未知" value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="状态">
                            <el-select v-model="form.status" placeholder="请选择状态" clearable>
                                <el-option label="正常" value="0" />
                                <el-option label="停用" value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <!-- <el-col :span="12">
              <el-form-item label="岗位">
                <el-select v-model="form.postIdList" multiple placeholder="请选择岗位" clearable>
                  <el-option
                    v-for="item in postOptions"
                    :key="item.postId"
                    :label="item.postName"
                    :value="item.postId"
                    :disabled="item.status == 1"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
                    <el-col :span="12">
                        <el-form-item label="角色">
                            <el-select v-model="form.roleIdList" multiple placeholder="请选择角色" clearable>
                                <el-option v-for="item in roleOptions" :key="item.roleId" :label="item.roleName" :value="item.roleId" :disabled="item.status == 1"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注">
                            <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <!-- 用户导入对话框 -->
        <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px" append-to-body>
            <el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers" :action="upload.url + '?updateSupport=' + upload.updateSupport" :disabled="upload.isUploading" :on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :auto-upload="false" drag>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip text-center" slot="tip">
                    <div class="el-upload__tip" slot="tip"><el-checkbox v-model="upload.updateSupport" /> 是否更新已经存在的用户数据</div>
                    <span>仅允许导入xls、xlsx格式文件。</span>
                    <el-link type="primary" :underline="false" style="font-size: 12px; vertical-align: baseline" @click="importTemplate">下载模板</el-link>
                </div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFileForm">确 定</el-button>
                <el-button @click="upload.open = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

  <script>
//   import { listUser, getUser, delUser, addUser, updateUser, resetUserPwd, changeUserStatus, deptTreeSelect } from "@/api/system/user";
import { getToken } from '@/utils/auth';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
    name: 'userManageHl',
    dicts: ['sys_normal_disable', 'sys_user_sex'],
    components: { Treeselect },
    data() {
        return {
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 用户表格数据
            userList: null,
            // 弹出层标题
            title: '',
            // 部门树选项
            deptOptions: undefined,
            // 租户树选择器
            tenantOptions: undefined,
            // 是否显示弹出层
            open: false,
            // 部门名称
            deptName: undefined,
            // 默认密码
            initPassword: undefined,
            // 日期范围
            dateRange: [],
            // 岗位选项
            postOptions: [],
            // 角色选项
            roleOptions: [],
            dictOption: [],
            // 表单参数
            form: {},
            defaultProps: {
                children: 'children',
                label: 'deptName'
            },
            // 用户导入参数
            upload: {
                // 是否显示弹出层（用户导入）
                open: false,
                // 弹出层标题（用户导入）
                title: '',
                // 是否禁用上传
                isUploading: false,
                // 是否更新已经存在的用户数据
                updateSupport: 0,
                // 设置上传的请求头部
                headers: { Authorization: 'Bearer ' + getToken() },
                // 上传的地址
                url: process.env.VUE_APP_BASE_API + '/system/user/importData'
            },
            // 查询参数
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                startCreateTime: '',
                endCreateTime: '',
                userName: undefined,
                phoneNumber: undefined,
                status: undefined,
                deptId: undefined
            },
            // 列信息
            columns: [
                { key: 0, label: `用户编号`, visible: true },
                { key: 1, label: `用户名称`, visible: true },
                { key: 2, label: `用户昵称`, visible: true },
                { key: 3, label: `部门`, visible: true },
                { key: 4, label: `手机号码`, visible: true },
                { key: 5, label: `状态`, visible: true },
                { key: 6, label: `创建时间`, visible: true }
            ],
            // 表单校验
            rules: {
                userName: [
                    { required: true, message: '用户名称不能为空', trigger: 'blur' },
                    { min: 2, max: 20, message: '用户名称长度必须介于 2 和 20 之间', trigger: 'blur' }
                ],
                nickName: [{ required: true, message: '用户昵称不能为空', trigger: 'blur' }],
                deptId: [{ required: true, message: '归属组织不能为空', trigger: 'blur' }],
                password: [
                    { required: true, message: '用户密码不能为空', trigger: 'blur' },
                    { min: 5, max: 20, message: '用户密码长度必须介于 5 和 20 之间', trigger: 'blur' }
                ],
                email: [
                    {
                        type: 'email',
                        message: '请输入正确的邮箱地址',
                        trigger: ['blur', 'change']
                    }
                ],
                phoneNumber: [
                    {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    watch: {
        // 根据名称筛选部门树
        deptName(val) {
            this.$refs.tree.filter(val);
        }
    },
    created() {
        this.getList();
        this.getDeptTree();
        this.getRoleListByTenantId();
        this.getTenantTree();
        //   this.getConfigKey("sys.user.initPassword").then(response => {
        //     this.initPassword = response.msg;
        //   });
    },
    methods: {
        /** 查询用户列表 */
        getList() {
            this.loading = true;
            this.tableData = []; //  清空table的值
            var queryParams = JSON.parse(JSON.stringify(this.queryParams));
            let json = {
                condition: queryParams,
                pageNo: queryParams.pageNo,
                pageSize: queryParams.pageSize
            };
            this.instance.post('/upms/api/user/paging', json).then((res) => {
                this.loading = false;
                this.userList = res.data.data.list;
                this.total = parseInt(res.data.data.total);
            });
        },
        /** 租户角色列表查询 */
        getRoleListByTenantId() {
            this.instance.post('/upms/api/role/getRoleListByTenantId', {}).then((res) => {
                if (res.data.code === 200) {
                    this.roleOptions = res.data.data;
                }
            });
        },

        getTenantTree: function () {
            let param = {
                pageNo: 1,
                pageSize: 100,
                condition: {}
            };
            this.instance.post('/upms/api/tenant/paging', param).then((res) => {
                // console.log(res);
                if (res.data.code === 200) {
                    let response = res.data.data.list;
                    function addLabel(items) {
                        if (items) {
                            items.forEach((val) => {
                                val.label = val.tenantName;
                                val.children = val.subTenantVoList;
                                if (val.subTenantVoList == null) {
                                    delete val.subTenantVoList;
                                    delete val.children;
                                }
                                val.children && addLabel(val.subTenantVoList);
                            });
                        } else {
                            return false;
                        }
                    }
                    addLabel(response);
                    this.tenantOptions = response;
                }
            });
        },

        /** 查询部门下拉树结构 */
        getDeptTree() {
            this.instance.get('/upms/api/dept/getDeptTree', {}).then((res) => {
                if (res.data.code === 200) {
                    let response = res.data.data;
                    function addLabel(items) {
                        if (items) {
                            items.forEach((val) => {
                                val.label = val.deptName;
                                val.id = val.deptId;
                                if (val.children == null) {
                                    delete val.children;
                                }
                                val.children && addLabel(val.children);
                            });
                        } else {
                            return false;
                        }
                    }
                    addLabel(response);
                    this.deptOptions = response;
                }
            });
        },
        // 筛选节点
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        // 节点单击事件
        handleNodeClick(data) {
            console.log(data);
            this.queryParams.deptId = data.deptId;
            this.handleQuery();
        },
        // 用户状态修改
        handleStatusChange(row) {
            let text = row.status === '0' ? '启用' : '停用';
            this.$modal
                .confirm('确认要"' + text + '""' + row.userName + '"用户吗？')
                .then(() => {
                    let json = {
                        userId: row.userId,
                        status: row.status
                    };
                    this.instance.post('/upms/api/user/edit', json).then((res) => {});
                    this.$modal.msgSuccess(text + '成功');
                })
                .catch(function () {
                    row.status = row.status === '0' ? '1' : '0';
                });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                userId: undefined,
                deptId: undefined,
                userName: undefined,
                nickName: undefined,
                password: undefined,
                phoneNumber: undefined,
                email: undefined,
                sex: undefined,
                status: '0',
                remark: undefined,
                postIdList: [],
                roleIdList: []
            };
            this.resetForm('form');
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.dateRange = [];
            this.resetForm('queryForm');
            this.queryParams.deptId = undefined;
            this.$refs.tree.setCurrentKey(null);
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.userId);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        // 更多操作触发
        handleCommand(command, row) {
            switch (command) {
                case 'handleResetPwd':
                    this.handleResetPwd(row);
                    break;
                case 'handleAuthRole':
                    this.handleAuthRole(row);
                    break;
                default:
                    break;
            }
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = '添加用户';
            // getUser().then(response => {
            //   this.postOptions = response.posts;
            //   this.roleOptions = response.roles;
            //   this.open = true;
            //   this.title = "添加用户";
            //   this.form.password = this.initPassword;
            // });
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.instance.get('/upms/api/user/' + row.userId, { params: {} }).then((res) => {
                if (res.data.code == 200) {
                    this.form = res.data.data;
                    this.open = true;
                    this.title = '修改用户';
                } else {
                    this.$modal.msgError(res.data.message);
                }
            });
        },
        /** 重置密码按钮操作 */
        handleResetPwd(row) {
            this.$prompt('请输入"' + row.userName + '"的新密码', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                inputPattern: /^.{5,20}$/,
                inputErrorMessage: '用户密码长度必须介于 5 和 20 之间'
            })
                .then(({ value }) => {
                    resetUserPwd(row.userId, value).then((response) => {
                        this.$modal.msgSuccess('修改成功，新密码是：' + value);
                    });
                })
                .catch(() => {});
        },
        /** 分配角色操作 */
        handleAuthRole: function (row) {
            const userId = row.userId;
            this.$router.push('/system/user-auth/role/' + userId);
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.form.userId != undefined) {
                        this.form.password = this.form.userId ? null : this.form.password;
                        this.instance.post('/upms/api/user/edit', this.form).then((res) => {
                            if (res.data.code == 200) {
                                this.$modal.msgSuccess('修改成功');
                                this.open = false;
                                this.getList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        });
                    } else {
                        this.instance.post('/upms/api/user/add', this.form).then((res) => {
                            if (res.data.code == 200) {
                                this.$modal.msgSuccess('新增成功');
                                this.open = false;
                                this.getList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const userIds = row.userId || this.ids;
            this.$modal
                .confirm('是否确认删除用户编号为"' + userIds + '"的数据项？')
                .then(function () {
                    return delUser(userIds);
                })
                .then(() => {
                    this.getList();
                    this.$modal.msgSuccess('删除成功');
                })
                .catch(() => {});
        },
        /** 导出按钮操作 */
        handleExport() {
            this.download(
                'system/user/export',
                {
                    ...this.queryParams
                },
                `user_${new Date().getTime()}.xlsx`
            );
        },
        /** 导入按钮操作 */
        handleImport() {
            this.upload.title = '用户导入';
            this.upload.open = true;
        },
        /** 下载模板操作 */
        importTemplate() {
            this.download('system/user/importTemplate', {}, `user_template_${new Date().getTime()}.xlsx`);
        },
        // 文件上传中处理
        handleFileUploadProgress(event, file, fileList) {
            this.upload.isUploading = true;
        },
        // 文件上传成功处理
        handleFileSuccess(response, file, fileList) {
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + response.msg + '</div>', '导入结果', { dangerouslyUseHTMLString: true });
            this.getList();
        },
        // 提交上传文件
        submitFileForm() {
            this.$refs.upload.submit();
        }
    }
};
</script>
<style scoped>
</style>